@import '../scss/variables.scss';

.svg {
  &--white {
    path {
      fill: $white;
    }
  }

  &--gold {
    path {
      fill: $gold__light;
    }
  }
}

label {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: $black_medium;
  margin-bottom: 5px;
  display: block;
}

.label {
  max-width: 311px;
  width: 100%;
  margin: 0 auto;
}

.btn {
  border: none;
  border-radius: 6px;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  justify-content: center;

  &__secondary {
    margin-top: 10px !important;
    border: 1px solid $primary;
    color: $primary;
    background: $white;

    div {
      margin-left: 10px;
    }
  }

  &__primary {
    display: flex;
    align-items: center;
    background: $primary;
    color: $white;
    font-weight: 500;
  }

  &__primary-default {
    display: flex;
    align-items: center;
    background-color: $white;
    color: $primary;
    font-weight: 500;
  }

  &__success {
    display: flex;
    align-items: center;
    background: $green_very_grey;
    color: $white;
  }

  &__warning {
    display: flex;
    align-items: center;
    background: $orange_light;
    color: $white;
  }

  &__outline-primary {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $primary;
    color: $primary;
  }

  &__outline-warning {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $orange_light;
    color: $orange_light;
  }

  &__outline-feedback {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $orange_light;
    color: $orange_light;
  }

  &__outline-danger {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $red_very_grey;
    color: $red_very_grey;
  }

  &__ghost-success {
    display: flex;
    align-items: center;
    background-color: $white;
    color: $green_very_grey;
  }

  &__rounded-success {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: $green_very_grey;
    color: $white;
  }

  &__rounded-danger {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: $red_very_grey;
    color: $white;
  }

  &__danger {
    display: flex;
    align-items: center;
    background-color: $red_very_grey;
    color: $white;
  }

  &__error {
    background: $white_slightly_transparent;
    border-radius: 2px;
    backdrop-filter: blur(16px);
    color: $primary;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    border-bottom: 2px solid $primary;

    & > .icon {
      margin-left: 12px;
    }
  }

  &__disabled {
    cursor: default;
    opacity: 0.7;
  }
}


.select {
  position: relative;
  width: 100%;

  &__search {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    padding: 14px 24px 13px 24px;
    border-bottom: 1px solid $black_hint;
    font-size: 14px;
    line-height: 120%;
    color: $black_medium;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }
  }

  &__disabled {
    & > button {
      cursor: default;
    }
  }

  &__header {
    margin-top: 5px;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    appearance: none;
    background: $white;
    border: 1px solid $blue_very_light;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 15px;

    &.active {
      border-radius: 6px 6px 0 0;
    }

    & > div {
      & > .icon {
        cursor: pointer;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: $black_medium;
  }

  &__box {
    width: 95%;
  }

  &__value {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: $black_medium;

    &.disabled {
      opacity: 0.6;
    }

    &.active {
      color: $black;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    z-index: 9;
    background: $white;
    border: 1px solid $blue_very_light;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    pointer-events: none;
    overflow-x: hidden;
    opacity: 0;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $white_dark;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: $black_gray;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $black_medium;
    }

    &.scroll {
      max-height: 365px;
      min-height: 100%;
      overflow-y: scroll;
    }

    &.active {
      pointer-events: all;
      opacity: 1;
    }

    & > button {
      cursor: pointer;
      text-align: left;
      outline: none;
      border: none;
      background-color: $white;
      padding: 14px 24px 13px 24px;
      font-size: 14px;
      line-height: 120%;
      color: $black_medium;
      border-radius: 0;

      &:hover {
        background-color: $white_dark;
      }
    }
  }
}

.input {
  position: relative;
  background-color: $white;

  & > .label {
    margin: 0;
  }

  & > input {
    color: $black_medium;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    padding: 15.5px 24px;
    border: 1px solid $blue_very_light;
    border-radius: 6px;
  }

  &::placeholder {
    color: $black_medium;
  }

  &__btn {
    position: absolute;
    right: 0;
    height: 49.35px;
    border-radius: 0 2px 2px 0;
    border: 1px solid $primary;
    background: $primary;
    width: 72px;
  }

  &__failed {
    & > input {
      box-shadow: inset 0 -1px 0 $red !important;
    }
  }

  &__success {
    & > input {
      box-shadow: inset 0px -1px 0px $green !important;
    }
  }
}

.list {
  &__number {
    min-width: 40px !important;
  }

  &_l-40-30 {
    left: 40px !important;
  }

  &_l-80-60 {
    left: 80px !important;
  }

  &__fix-column {
    left: 0;
  }

  &__shadow {
    box-shadow: 8px 0 12px rgba(0, 0, 0, 0.08) !important;
  }

  &_pl-0 {
    padding-left: 0 !important;
  }

  &__fix-column_second, &__fix-column {
    position: sticky;
    left: 0;
    background: $white;
  }

  &__content {
    &-wrap {
      display: flex;
    }
  }

  &__label {
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: $black_low;
    margin-bottom: 20px;
  }

  &-wrapper {
    padding-bottom: 5px;
    overflow-x: scroll;
    overflow-y: none;
    border: 1px solid $blue_very_light;
    border-radius: 6px;

    &::-webkit-scrollbar {
      border-top: 1px solid $blue_very_light;
      height: 6px;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: #666668;
      border-radius: 6px;
    }

  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $blue_very_light;
    height: 48px;

    p {
      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }

  &-header-long {
    p {
      padding: 10px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }

  &__title {
    color: $black_low;
  }

  &-header-wrap {
    padding: 20px 16px;
    display: flex;
    background: $white;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    position: relative;
  }

  &__row {
    width: 100%;
    height: 48px;
    font-size: 14px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    color: $black_medium;


    //оставил на случай если ФИО и т.п будет слишком длинным, и будет выходить за пределы колонки (откомментить P)
    // p {
    //   white-space: pre-wrap !important;
    // }
  }

  &__wrap {
    &:nth-child(2n+2) {
      background: $light_ultra_grey;
    }

    &:first-child {
      .list__row {
        cursor: default !important;
      }
    }

    &:first-of-type {
      .list__row {
        .list__checkbox {
          display: none;
        }
      }
    }

    &.chose {
      border-bottom: 1px solid $white;
      background-color: $white_ultra_grey;
    }

    & > .list-input {
      z-index: 4;
    }
  }

  &__checkbox {
    position: relative;

    & > .checkbox__box {
      position: absolute;
      top: -5px;
      right: 0;
    }
  }

  &__column {
    width: 100%;
    background: $white;

    .list__row {
      width: 100%;

      & > p {
        padding: 0 12px;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & > a {
        font-size: 14px;
        color: $black_medium;
      }

      .success {
        color: $green;
      }

      .failure {
        color: $red;
      }

      .warning {
        color: $orange;
      }

      &--success {
        padding-right: 8px;
      }

      &--location {
        width: 450px;
      }
    }

    &:last-child {
      .list__row {
        padding-right: 16px;
      }
    }
  }

  &-input {
    min-width: 200px;
    display: flex;
    align-items: center;
    position: absolute;
    background: $white;
    padding: 18px 16px;
    transform: translate(0px, -50px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);

    input {
      border: none;
      width: 100%;
    }

    button {
      border: none;
      background: transparent;
      padding: 0;
      width: 20px;
      height: 20px;

      &:first-child {
        margin-right: 10px;
      }
    }

    &-btns {
      display: flex;
    }
  }
}

.progress {
  display: flex;
  align-items: center;
  width: 100%;

  &-wrap {
    position: relative;
    background: $light_very_grey;
    height: 20px;
    width: 100%;
    border-radius: 100px;
  }

  &-percent {
    height: 100%;
    position: absolute;
    background: $blue_light;
    border-radius: 100px;
  }

  &__data {
    font-size: 14px;
    color: $black_medium;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  p {
    margin-left: 10px;
  }
}

.datepicker {
  padding: 0;
}

.react-datepicker-popper {
  .react-datepicker {
    font-family: Montserrat;
    font-style: normal;
    padding: 15px;
    border: 1px solid #E1E3F1;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);

    &--time-only {
      padding: 0;
    }

    &-time__header {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: $primary;
    }

    &__time-list-item {
      font-size: 14px;
      line-height: 120%;
      color: $black_medium;
    }

    &__time-list {
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $white_dark;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: $primary;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $primary_hover;
      }
    }

    &__triangle {
      display: none;
    }

    &__navigation--previous {
      left: 10%;
      top: 8%;
    }

    &__navigation--next {
      right: 10%;
      top: 8%;
    }

    &__navigation-icon::before {
      border: none;
    }

    &__navigation-icon--next {
      background: url("../icons/arrow-right.svg") no-repeat center center;
      background-size: 24px;
      width: 24px;
      height: 24px;

      &::before {
        transform: none;
        left: 0;
      }
    }

    &__navigation-icon--previous {
      background: url("../icons/arrow-left.svg") no-repeat center center;
      background-size: 24px;
      width: 24px;
      height: 24px;

      &::before {
        transform: none;
        left: 0;
      }
    }

    &__current-month {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: $primary;
    }

    &__day-names {
      margin-top: 22px;
      padding-bottom: 10px;
    }

    &__day-name {
      font-size: 14px;
      line-height: 120%;
      color: $black_medium;
    }

    &__header {
      border-color: $light_very_grey;
    }

    &__month {
      margin-top: 10px;
    }

    &__day {
      font-size: 14px;
      color: $black_medium;

      &:hover {
        border-radius: 100px;
      }
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
      background-color: $primary;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: $primary;
    }

    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
      font-size: 16px;
      opacity: 0.4;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      border-radius: 100px;
      background: none;
      color: $black_medium;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      border-radius: 100px;
      background-color: $primary;
      color: $white;
    }
  }

  .react-datepicker__header {
    background-color: $white;
  }

  .react-datepicker__current-month {
    color: $primary;
  }
}

//Loader
.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}

.loader {
  border: 8px solid #C4C4C4;
  border-radius: 50%;
  border-top: 8px solid #2B3990;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//Loader Btn
.loader-btn {
  display: flex;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;

  &-primary {
    left: -5%;

    & > div {
      border-color: $primary transparent transparent transparent !important;
    }
  }

  & > div {
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    //background: conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 0.24) 0deg, #FFFFFF 359.96deg, rgba(255, 255, 255, 0.24) 360deg);
    border: 4px solid;
    border-radius: 50%;
    animation: spin-loader-btn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes spin-loader-btn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//Chart
.bar {
  width: 100%;
  height: 300px;

}

//Icon
.svg {
  &--white {
    path {
      fill: $white;
    }
  }

  &--blue {
    path {
      fill: $blue;
    }
  }

  &--primary {
    path {
      fill: $primary;
    }
  }
}

//Divider
.divider {
  width: 100%;
}

//Map
.map {
  display: flex;
  justify-content: center;

  & > svg > g > path {
    cursor: pointer;
  }
}

//Rating
.rating {
  display: flex;

  &__label {
    margin-left: 10px;
    transition: color ease-in-out .2s;

    &:first-child {
      margin-left: 0;
    }

    & > .input {
      display: none;
    }

    & > .icon {
      cursor: pointer;
    }
  }
}

//Card
.card {
  background: $white;
  border: 1px solid $white_very_grey;
  padding: 25px 30px;
}

//TextArea
.textarea {
  & > textarea {
    outline: none;
    padding: 20px 25px;
    background: $white;
    border: 1px solid $white_very_grey;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $black_low;
    width: 100%;

    &::placeholder {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $black_low;
    }
  }
}

.textarea-words {
  margin-top: 5px;
  text-align: right;
}

//Diagram
//Pyramid
.pyramid {
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    width: 318px;
    border: 1px solid $gold;
    border-radius: 8px;
    padding: 20px;
    color: $black_medium;
    line-height: 120%;
  }
}

//Pagination
.pagination {
  max-width: 721px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  margin: 0 auto;

  li {
    width: 36px;
    height: 36px;
    display: flex;
    border-radius: 50%;

    & > a {
      width: 100%;
      height: 100%;
      color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .previous {
    margin-right: 40px;

    & > a {
      display: flex;
      align-items: center;
    }
  }

  .next {
    margin-left: 40px;

    & > a {
      display: flex;
      align-items: center;
    }
  }

  .pagination__link {
    &--active {
      background: $white_dark;
      font-weight: 600;
      color: $black_medium;
    }
  }
}

//CheckBox
.checkbox {
  display: flex;
  align-items: center;

  &__input {
    display: none;
    margin: 0;
    position: relative;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  &__box {
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid $black_hint;
    border-radius: 4px;
  }

  &__title {
    margin-left: 26px;
    font-size: 13px;
    line-height: 120%;
    color: $black_low;
  }

  &__input:checked + &__box::before {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $primary;
    background-image: url("../icons/check-white.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
  }

  &__input:disabled + &__box::before {
    background-color: $white;
  }

  &__input:checked:disabled + &__box::before {
    background-color: $light_grey;
  }
}


//Back
.back {
  width: 80px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  & > .icon {
    margin-right: 5px;
  }

  & > span {
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: $blue;
  }
}

//Empty
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;

  &__title {
    & > h3 {
      color: $black_low;
      font-size: 16px;
    }
  }

  &__icon {
    & > .icon {
      margin-top: 20px;

      & > svg {
        width: 60px;
        height: 60px;
      }
    }
  }
}

//Editor
.toolbarClassName {
  margin-bottom: 0 !important;
}

.editorClassName {
  height: 250px !important;
  padding: 6px 5px 0;
  border-left: 1px solid $white_very_grey;
  border-right: 1px solid $white_very_grey;
  border-bottom: 1px solid $white_very_grey;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $black_medium;

  .public-DraftEditorPlaceholder-root {
    color: $black_medium;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $white_dark;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $black_gray;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $black_medium;
  }
}


//Tabs
.tabs {
  border: 1px solid $primary;
  border-radius: 100px;

  & > .tabs__list {
    display: flex;
    align-items: center;
    padding: 3px;

    & > .tab {
      padding: 8px 25px;
      display: flex;
      justify-content: center;

      &.active {
        background-color: $primary;
        border-radius: 100px;

        & > .tab__link {
          color: $white;
        }
      }
    }
  }
}

@media (max-width: 1083px) {
  //Diagram
  //Pyramid
  .pyramid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__svg {
      width: 100%;
    }

    &__text {
      position: static;
      margin: 20px auto;
      max-width: 500px;
      width: 100%;
    }
  }
}

@media (max-width: 1023px) {
  .pagination {
    justify-content: space-between;

    .previous {
      margin-right: 0;
    }

    .next {
      margin-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .list {
    &__data_text {
      white-space: pre-wrap !important;
    }

    &__number {
      width: 7% !important;
    }

    &_l-40-30 {
      left: 30px !important;
    }

    &_l-80-60 {
      left: 60px !important;
    }

    &__column {
      .list__row {
        & > p {
          height: inherit;
          padding: 0 8px;
          white-space: break-spaces;
        }
      }
    }

    &__wrap {
      & > .list-input {
        position: fixed;
        width: 320px !important;
        top: 50%;
        left: 17%;
      }
    }

    &-header {
      display: none;
    }
  }

  .select {
    max-width: none !important;
    margin-right: 0 !important;
    margin-left: 0 !important;

    &__column {
      .list__row {
        & > p {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & > a {
          font-size: 14px;
          color: $black_medium;
        }

        .success {
          color: $green;
        }

        .failure {
          color: $red;
        }

        .warning {
          color: $orange;
        }
      }

      &:first-child {
        .list__row {
          padding-left: 16px;
        }
      }

      &:last-child {
        .list__row {
          padding-right: 16px;
        }
      }
    }
  }

  .tabs {
    & > .tabs__list {
      & > .tab {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .list {
    &__fix-column_second {
      width: auto;
    }

    &__wrap {
      & > .list-input {
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .pyramid {
    &__svg {
      height: 200px;
    }
  }
}

@media screen and (max-width: 374px){
  .list {
    &-input {
      width: 240px !important;
    }
  }
}

@media screen and (max-width: 322px) {
  .btn {
    &__secondary {
      margin-right: 0 !important;
    }
  }
}