$primary: #2B3990;
$primary_hover: #1D296F;
$primary_pressed: #111A55;
$primary_disabled: #8F99D6;
$black: #1E1F25;
$black_medium: #4A4C54;
$black_gray: #666668;
$black_low: #7E808A;
$black_hint: #B6B8BF;
$gold: #F4B75B;
$white: #FFFFFF;
$white_dark: #F0F4FF;
$white_background: #FBFBFD;
$blue: #2861B7;
$blue_light: #E2F0FF;
$blue_very_light: #E1E3F1;
$white_very_grey: #E3E3E3;
$grey: #8A8EAD;
$gray_translucent: rgba(217, 217, 217, 0.4);
$light_grey: #E5E5E5;
$light_ultra_grey: #F7F7F7;
$light_very_grey: #EBEBEB;
$gold_shadow: rgba(232, 202, 130, 0.8);
$white_slightly_transparent: rgba(255, 255, 255, 0.9);
$black_very_grey: rgba(43, 57, 144, 0.04);
$black_highly_transparent: rgba(0, 0, 0, 0.24);
$accent: #0C314E;
$accent_lighter: #093864;
$dark_milky: #E8CA82;
$gold_shadow: rgba(232, 202, 130, 0.8);
$white_slightly_transparent2: rgba(255, 255, 255, 0.7);
$black_highly_transparent: rgba(0, 0, 0, 0.24);
$red: #E44C4C;
$red_light: #FF6060;
$blue_gradient: linear-gradient(90deg, #4457D0 0%, #369FFF 100%);
$green_light: #75F390;
$green: #45CF63;
$green_very_grey: #27AE60;
$green_ultra_grey: #5BAD2B;
$orange: #FF7F0A;
$red_very_grey: #EE4902;
$orange_light: #FF932F;
$gold__light: #FFD029;
$ghost_dark: rgba(73, 73, 73, 0.9);
$white_ultra_grey: #DDDDDD;
$blue_ultra_light: #F5F8FD;
