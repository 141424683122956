.container {
  max-width: 1176px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: block;
  padding: 0 16px;
  position: relative;
}
@media(min-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media(max-width: 1440px) {
  .container {
    max-width: 1176px;
  }
}