@import '/src/scss/variables.scss';

.sidebar {
  border-right: 1px solid $light_very_grey;
  max-width: 259px;
  width: 100%;

  &--hide {
    width: 62px;
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    background: none;
    border: none;
    width: 100%;
    height: 48px;

    &--auto {
      justify-content: flex-end;
    }
  }

  &__item {
    padding: 0 15px;
    border-left: 2px solid $white;
    display: flex;
    align-items: center;
    height: 48px;

    p {
      margin-left: 15px;
      font-size: 16px;
    }
  }

  &__active {
    border-left: 2px solid $primary;

    &--hide {
      border-radius: 2px;
      background: $primary;
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}