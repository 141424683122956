@import '/src/scss/variables.scss';

.header {
  background: $white;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 72px;
  position: fixed;
  top: 0;
  z-index: 10;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__auth {
    display: flex;
    align-items: center;
  }

  &__profile {
    display: flex;
    align-items: center;

    .profile {
      &__name, &__role {
        color: $black_medium;
        line-height: 120%;
      }

      &__role {
        text-align: right;
      }

      &__avatar {
        margin: 0 18px 0 15px;
      }
    }
  }

  &__logout {
    border: none;
    background: none;

    & > .logout__img {
      border-left: 1px solid $light_very_grey;
      padding-left: 18px;
    }
  }

  &__burger {
    display: none;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .header {
    &__profile, &__logout {
      display: none;
    }

    &__burger {
      display: block;
    }
  }
}