@import './variables.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  z-index: 5;
  padding: 10px;

  &-wrap {
    background: $white;
    padding: 36px;
    border-radius: 8px;
    position: relative;
  }

  &__close {
    background: transparent;
    padding: 0;
    border: none;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;

    img {
      width: 100%;
    }
  }

  h5 {
    margin-bottom: 24px;
    color: $black_medium;
  }

  .red {
    background: url('../icons/danger.svg') no-repeat 0 50%;
    padding-left: 36px;
    font-size: 13px;
    line-height: 130%;
    margin-bottom: 24px;

    span {
      color: $blue;
      cursor: pointer;
    }
  }

  &-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border: 1px dashed $grey;
    border-radius: 10px;
    margin-bottom: 32px;
    cursor: pointer;

    &.draggable {
      background: $black_very_grey;
    }

    img {
      margin-bottom: 16px;
    }

    p {
      font-size: 12px;
      line-height: 120%;
      color: $grey;

      span {
        font-weight: bold;
      }
    }
  }

  &__progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-bottom: 30px;

    & > .file {
      flex-basis: 15%;
    }

    & > .content {
      flex-basis: 80%;

      & > .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > .progress {
        margin-top: 10px;

        & > p {
          display: none;
        }
      }
    }

    & > .close {
      flex-basis: 5%;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }

  &__error {
    width: 339px;
    & > h4 {
      font-weight: bold;
      font-size: 13px;
      line-height: 120%;
      color: $black_medium;
    }

    & > .content {
      margin-top: 16px;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 10px;

        & > div {
          font-weight: normal;
          font-size: 13px;
          line-height: 120%;
          color: $black_medium;
          width: 85px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;

          & > p {
            margin-left: 5px;
          }
        }

        & > p {
          font-weight: normal;
          font-size: 13px;
          line-height: 120%;
          color: $black_medium;
        }
      }
    }

    .modal__btn {
      margin-top: 41px;
    }
  }

  &__success {
    & > .modal-wrap {
      padding: 64px 60px 63px 72px;
      position: relative;
      background-color: $green_ultra_grey;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #FFFFFF;

      & > .modal__close > .icon > svg > path {
        fill: $white;
      }

      &:before {
        content: "";
        width: 61px;
        height: 129px;
        display: block;
        position: absolute;
        background: url('../icons/modal-success.png') no-repeat center center/cover;
        top: -10%;
        left: 2%;
      }
    }

    .modal__title {
      font-weight: 600;
      font-size: 17px;
      line-height: 120%;
      text-align: center;
      color: $white;
    }

    .modal__description {
      text-align: center  ;
      margin-top: 20px;
      font-weight: normal;
      font-size: 15px;
      line-height: 120%;
      color: $white;
      opacity: 0.7;
    }
  }

  &__ghost {
    & > .modal-wrap {
      padding: 30px;
      background-color: $ghost_dark;

      & > .modal__close {
        top: 8px;
        right: 8px;
        & > .icon > svg > path {
          fill: $white;
        }
      }
    }

    .modal__title {
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      color: $white;
    }
  }
}