@import "src/scss/variables";

.table {
  width: 100%;
  position: relative;
  display: flex;
  overflow-y: auto;
  border-radius: 6px;
  border: 1px solid $blue_very_light;

  &__column {
    width: 100%;

    &:first-child {
      & > .table__cell {
        padding-left: 13px;
      }
    }

    &:last-child {
      & > .table__cell {
        padding-left: 0;
        padding-right: 13px;
      }
    }
  }

  &__cell {
    overflow: visible;
    padding: 10px 24px;
    background-color: $white;
    border-bottom: 1px solid $white;
    white-space: nowrap;
    height: 48px;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: $black_medium;

    &:nth-child(2n+1) {
      background: $light_ultra_grey;
    }

    &:nth-last-child {
      display: none;
    }

    &:first-child {
      font-weight: 400;
      color: $black_low;
      background-color: $white;
      border-bottom: 1px solid $blue_very_light;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &::-webkit-scrollbar {
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 4px;
    border: 4px solid $white;
    background-color: $blue_very_light;
  }
}