@import "../../scss/variables";

.burger {
  width: 100%;
  height: calc(100vh - 72px);
  justify-content: right;
  position: absolute;
  top: 72px;
  right: 0;
  display: none;

  &--show {
    pointer-events: all;
  }

  &--hide {
    pointer-events: none;
  }

  &__content {
    display: none;
    position: absolute;
    width: 100%;
    height: calc(100vh - 72px);
    background: $gray_translucent;

    &--show {
      opacity: 1;
      transition: .5s ease-out;
      pointer-events: all;
    }

    &--hide {
      opacity: 0;
      transition: .5s;
      pointer-events: none;
    }
  }

  &__menu {
    width: calc(100% - 45px);
    height: 100%;
    padding: 24px 24px 24px 0;
    background: $white;

    &--show {
      transform: translateX(45px);
      transition: .5s ease-out;
    }

    &--hide {
      transform: translateX(120%);
      transition: .5s;
    }

    & > .header__burger-content > .sidebar__arrow {
      display: none;
    }
  }

  &__info {
    margin-left: 17px;

    .info {
      &__name {
        font-size: 16px;
      }

      &__role {
        margin-top: 10px;
      }
    }
  }

  &__divider {
    width: calc(100% - 17px);
    border-radius: 1px;
    margin: 24px 0 24px 17px;
  }
}

@media (max-width: 768px) {
  .burger {
    display: flex;

    &__content {
      display: block;
    }

    &__close {
      margin-left: 17px;
      display: flex;
      align-items: center;

      .close {
        &__btn {
          border: none;
          background: none;
          padding: 0;
        }

        &__text {
          font-size: 16px;
          margin-left: 15px;
        }
      }
    }
  }
}