@import '/src/scss/variables.scss';

.app {
  overflow-x: hidden;

  &-wrap {
    margin-top: 72px;
    display: flex;
    background: $white;
    height: 100%;
    min-height: 92vh;
  }
}

@media (max-width: 768px) { //940
  .app {
    &-wrap {
      background: $light_ultra_grey;
    }
  }
}