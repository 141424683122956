@import "src/scss/variables";

.upload {
  max-width: 398px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;

  &__input {
    background-color: #F5F7FF;
    border: 1px dashed $grey;
    border-radius: 6px;
    padding: 8px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > p {
      text-align: center;
    }
  }

  &__title {
    margin-top: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    color: $grey;
  }

  &__subTitle {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: $grey;

    & > strong {
      font-weight: 700;
    }
  }

  &__desc {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: $grey;
  }

  &__files {
    margin-top: 16px;
  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;

      & > p {
        margin-left: 10px;
      }
    }
  }

  &__doc {
    & > div {
      width: 36px;
      height: 36px;
      background-color: $blue_ultra_light;
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__action {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}