@import './variables.scss';

body {
	background: $light_ultra_grey;
	overflow: auto;
}

.title {
	color: $black_medium;
	&__primary {
		color: $primary;
	}
	&__blue {
		color: $blue !important;
	}
}

.modal {
	&-open {
		overflow: hidden;
	}
}

.rotate {
	transform: rotate(180deg);
}

.reverse {
	flex-direction: row-reverse;
}

.desktop {
	&-block {
		display: block;
	}
	&-flex {
		display: flex;
	}
}

.mobile {
	&-block {
		display: none;
	}
	&-flex {
		display: none;
	}
}

.d-flex {
	display: flex;
}
.red {
	color: $red;
}
#file {
	display: none;
}

@media(max-width: 768px) {
	.mobile {
		&-block {
			display: block;
		}
		&-flex {
			display: flex;
		}
	}
	.desktop {
		&-block {
			display: none;
		}
		&-flex {
			display: none;
		}
	}
}