@import "src/scss/variables";

.badge {
  border-radius: 100px;
  max-width: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__count {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $white;
  }
}