@import "../../../scss/styles";

//AppLoader
.boxes-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxes {
  height: calc(32px * 2);
  width: calc(32px * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(32px * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);

  .box {
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    position: absolute;
    transform-style: preserve-3d;

    &:nth-child(1) {
      transform: translate(100%, 0);
      animation: box1 800ms linear infinite;
    }

    &:nth-child(2) {
      transform: translate(0, 100%);
      animation: box2 800ms linear infinite;
    }

    &:nth-child(3) {
      transform: translate(100%, 100%);
      animation: box3 800ms linear infinite;
    }

    &:nth-child(4) {
      transform: translate(200%, 0);
      animation: box4 800ms linear infinite;
    }

    & > div {
      --background: #{$blue};
      --translateZ: calc(32px / 2);
      --rotateY: 0deg;
      --rotateX: 0deg;
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--background);
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));

      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        --background: darken($blue, 15%);
        right: 0;
        --rotateY: 90deg;
      }

      &:nth-child(3) {
        --background: darken($blue, 5%);
        --rotateX: -90deg;
      }

      &:nth-child(4) {
        background-color: $blue_very_light;
        top: 0;
        left: 0;
        --translateZ: calc(32px * 3 * -1);
      }
    }
  }
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}